<template>
  <div class="row">
<!--    <div v-if="showAddButton" class="col-lg-12 d-flex justify-content-end mb-h">-->
<!--            <div class="pl-1 ">-->
<!--          <button class="btn btn-info btn-md" @click="openRadiusWizard" v-if="!isDisable">-->
<!--            {{ $t('radius.addRadius') }}-->
<!--          </button>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div v-if="showAddButton" class="col-lg-12 d-flex justify-content-between mb-h">-->
<!--            <div class="pl-1 ">-->
<!--          <button class="btn btn-info btn-md" @click="openEditModal" v-if="!isDisable">-->
<!--            {{ $t('general.add') }}-->
<!--          </button>-->
<!--        </div>-->
<!--      <div class="pl-1 ">-->
<!--          <button class="btn btn-info btn-md" @click="openRadiusWizard" v-if="!isDisable">-->
<!--            {{ $t('radius.radiusWizardButtonCaption') }}-->
<!--          </button>-->
<!--        </div>-->
<!--    </div>-->
    <div class="col-lg-12 mb-1" v-if="showAddButton">
      <div class="p-0 filter-block">
        <div class="w-100">
          <div class="filters filters__radius w-100">
            <div class="col-lg-3 p-0 pr-1">
            <div class=" ">
              <div class=" ">
                <div class="input-group input-group-sm ">
                  <span class="input-group-addon"><i class="fa fa-search"></i></span>
                  <input
                    @input="searchInputHandler(filters.query)"
                    id="search"
                    name="search"
                    :placeholder="$t('general.searchPlaceHolder')"
                    class="form-control"
                    v-model="filters.query"
                  />
                  <span class="clear-input text-muted" v-if="filters.query != ''" @click="filters.query = ''">
                    <i @click="searchInputHandler('')" class="fa fa-remove"></i>
                  </span>
                </div>
              </div>
            </div>
            </div>

<!--            <div class="filters__multiple">-->
<!--              <div class="input-group input-group-sm filters__input">-->
<!--                <span class="input-group-addon filters__field-icon"><i class="fa fa-map-marker"></i></span>-->
<!--                <select @change="refreshRadiusList" class="form-control form-control-sm filters__field" v-model="filters.location">-->
<!--                  <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>-->
<!--                  <option v-for="location in locationsList" :value="location.id" :key="location.id">-->
<!--                    {{ location.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--                        <div-->
<!--                class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">-->
<!--                <div-->
<!--                  class="  d-flex align-items-end justify-content-start"-->
<!--                >-->
<!--                  <Switch-component-->
<!--                    :disabled="this.filters.location === 'All locations'"-->
<!--                    v-model="filters.with_childs"-->
<!--                    class="switcher-with-childs-for-use-in-filters"-->
<!--                    @change="refreshRadiusList"-->
<!--                  />-->
<!--                </div>-->
<!--                <div-->
<!--                  class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">-->
<!--                  <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--                </div>-->
<!--              </div>-->


<div class="col-lg-3 p-0 mr-1" v-if="areLocationsVisible">
                                        <div
                  class="input-group-for-location-select-in-radius--filters    ">
                                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-radius--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="getPaginatedRadiusListWithClearPagination"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>
                </div>
</div>
                        <div class="col-lg-3 mb-h pl-0">
              <div class="input-group input-group-sm filters__sort flex-wrap mr-1">
                <span class="input-group-addon filters__field-icon">
                  <i
                    class="fa text-primary icon-sort"
                    :class="[filters.sort_by.order === 1 ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc']"
                    @click="toggleSortMode"
                  />
                </span>
                <select @change="handleSelectSortField" id="filter"
                        class="form-control form-control-sm filters__field"
                        v-model="filters.sort_by.field">
                  <option value="name">{{ $t('general.name') }}</option>
                  <option value="hostname">{{$t('radius.addressInTableTitle')}}</option>
                  <option value="auth_port">{{ $t('radius.authPort') }}</option>
                  <option value="acc_port">{{ $t('radius.acctPort') }}</option>
                </select>
              </div>
            </div>

          </div>
        </div>
<!--        <div class="pl-1 mb-1">-->
<!--          <button class="btn btn-warning btn-md" @click="openRadiusWizard" v-if="!isDisable">-->
<!--            {{ $t('radius.addRadius') }}-->
<!--          </button>-->
<!--        </div>-->
      </div>
      <div v-if="isFiltersActive" class=" mt-h">
        <WButton secondary outline sm
          @click="resetAllFilters"
        >
          <i class="fa fa-close"></i>
          {{ $t('general.resetAllFilters') }}
        </WButton>
      </div>
      <!-- <div class="btn-group btn-group-sm float-left" data-toggle="button">
                <label class="btn btn btn-outline-warning" :class="{'active': filters.typeFilter == 'acct'}">
                    <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'acct')">Acct
                </label>
                <label class="btn btn btn-outline-warning" :class="{'active': filters.typeFilter == 'auth'}">
                    <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'auth')">Auth
                </label>
                <label class="btn btn btn-outline-warning" :class="{'active': filters.typeFilter == 'both'}">
                    <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'both')">Both
                </label>
            </div> -->
    </div>
    <div v-if="showAddButton" class="col-lg-12 d-flex justify-content-between mb-h">
      <div class="d-flex">
        <WButton info
          customClass="mr-1"
          v-if="!isDisable"
          @click="openCreateModal"
        >
          {{ $t('general.add') }}
        </WButton>
        <div>
          <WButton info
            v-if="!isDisable"
            @click="openMultiselectDeletingDialogforRadius"
            :disabled="selectedRADIUSId.length <= 0"
          >
            {{ $t('general.delete') }}
          </WButton>
        </div>
      </div>
      <div class="pl-1 ">
        <WButton info
          v-if="!isDisable"
          @click="openRadiusWizard"
        >
          {{ $t('radius.radiusWizardButtonCaption') }}
        </WButton>
      </div>
    </div>
    <div class="col-lg-12" v-if="showAddButton">
      <div class="radius" :class="{ 'edit-visible': editVisible }">
        <div class="radius-list animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <!--                  <div class="loader loader-backdrop loader-backdrop-radius" v-if="loadingRadius"></div>-->
                  <div v-if="loadingRadius"><Loader-spinner /></div>
                  <div class="card-header">
                    <div>
                      <i class="fa fa-align-justify"></i>
                      {{ $t('radius.radiusList') }}
                      <span class="text-muted ml-3" v-if="!loadingRadius && filterItems(radiusList)">
                        {{ filterItems(radiusList).length }} {{ $t('wlans.itemsOf') }}
                        {{ this.totalResult }}
                      </span>
                    </div>
                  </div>
                  <div class="card-block">
                    <table class="table">
                      <thead>
                        <tr>
                               <th v-if="!isDisable">                       <Checkbox-component id="checkAll" :checked="isAllChecked" @click="checkAllRADIUS" />
                          </th>
                          <th>{{ $t('general.name') }}</th>
                          <th>{{ $t('radius.addressInTableTitle') }}</th>
<!--                          <th>{{ $t('radius.secret') }}</th>-->
<!--                          <th>{{ $t('radius.password') }}</th>-->
                          <th>{{ $t('radius.authPort') }}</th>
                          <th>{{ $t('radius.acctPort') }}</th>
<!--                          <th>{{ $t('general.location') }}</th>-->
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr v-if="this.filteredRadius.length == 0">
                                                    <td class="radius-row-nodata" colspan="7"> No results found </td>
                                                </tr> -->
                        <tr
                          v-for="(radius, index) in filterItems(radiusList)"
                          :data-index="index"
                          @click="openEditModal(radius)"
                          class="radius-row"
                          :data-id="radius.id"
                          :class="{
                            selected: index === radiusSelectedIndex,
                            'radius-loading': radiusSpinner(radius.id)
                          }"
                        >
                          <td v-if="!isDisable" @click.stop="checkRADIUS" :id="`select-${radius.id}`">
                            <Checkbox-component
                              :id="`select-${radius.id}`"
                              :checked="selectedRADIUSId.includes(radius.id)"

                            />
                          </td>
                          <td class="responsive-row">{{ radius.name }}</td>
                          <td class="responsive-row">{{ radius.hostname }}</td>
<!--                          <td class="responsive-row">{{ radius.secret }}</td>-->
                          <td class="responsive-row">{{ radius.auth_port }}</td>
                          <td class="responsive-row">{{ radius.acc_port }}</td>
<!--                          <td class="responsive-row">{{ locationName(radius.base_location) }}</td>-->
                          <td class="action-row" v-if="!editVisible && !isDisable">
                            <!-- <i class="fa fa-pencil fa-lg mr-q text-info user-action" data-action="edit"></i> -->
                            <i
                              v-if="!isDisable"
                              class="fa fa-remove fa-lg mr-q text-danger user-action"
                              data-action="delete"
                              @click.stop.prevent="openDeletingDialog(radius.id)"
                            ></i>
                          </td>
                        </tr>
                        <tr v-if="!filterItems(radiusList).length">
                          <td colspan="7" class="no-data-row">
                            <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                                      <div v-if="currentPageNumber !== -1"
                       class="card-block text-center p-0 d-flex justify-content-start align-items-center">
                    <div>
                      <i :class="{'text-muted': firstPageButtonDisabled}"
                         :disabled="firstPageButtonDisabled" @click.stop="firstPageClickHandler"
                         role="button"
                         class="mr-h fa fa-fast-backward"></i>
                      <i :class="{'text-muted': previousPageButtonDisabled}"
                         :disabled="previousPageButtonDisabled"
                         @click.stop="previousPageClickHandler" role="button"
                         class="mr-1  fa fa-arrow-left"></i>
                      <span class="mr-1">{{currentPageNumber}} {{$t('general.of')}} {{maxPageNumber}}</span>
                      <i :class="{'text-muted': nextPageButtonDisabled}"
                         :disabled="nextPageButtonDisabled" @click.stop="nextPageClickHandler"
                         role="button" class="mr-h  fa fa-arrow-right"></i>
                      <i :class="{'text-muted': lastPageButtonDisabled}"
                         :disabled="lastPageButtonDisabled" @click.stop="lastPageClickHandler"
                         role="button"
                         class="mr-2 fa fa-fast-forward"></i>
                    </div>
                    <div class="d-flex flex-row align-items-end">
                      <div style="width: 4em" class="mr-h">
                        <select @change="handleItemsPerPageSelect" id="items-per-page-select"
                                class="form-control form-control-sm filters__field pagination-limit-selector"
                                v-model.number="limit">
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div>
                        <small class="muted">
                          {{ $t('general.itemsPerPage')}}
                        </small>
                      </div>
                    </div>

                  </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

<!--        <div class="radius-edit" v-if="editVisible">-->
<!--          <div class="row sticky-top">-->
<!--            <div class="col-lg-12">-->
<!--              <div class="card">-->
<!--                <div class="card-header pb-0">-->
<!--                  <div class="row">-->
<!--                    <div class="col-lg-12">-->
<!--                      <div class="edit-header">-->
<!--                        <div class="edit-header__info">-->
<!--                          <div class="edit-header__name">-->
<!--                            <span class="h4">{{ radiusEditCurrent.name }}</span>-->
<!--                          </div>-->
<!--                          <div class="edit-header__tags"></div>-->
<!--                          <button-->
<!--                            type="button"-->
<!--                            class="btn btn-outline-secondary btn-sm float-right edit-btn-close new-modern-style-btn-close-panel"-->
<!--                            @click="disableEditMode"-->
<!--                          >-->
<!--                            <i class="fa fa-close"></i>-->
<!--                          </button>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card-block pt-0">-->
<!--                  <small class="text-muted" v-if="!isDisable">{{ $t('general.editingMode') }}</small>-->
<!--                  <small class="text-muted" v-if="isDisable">{{ $t('general.readOnlyMode') }}</small>-->
<!--                  <div class="form-group mt-1">-->
<!--                    <label for="ip address">-->
<!--                      {{ $t('radius.addressField') }}-->
<!--                      <span class="text-muted small">({{ $t('radius.addressFieldHint') }})</span>-->
<!--                    </label>-->
<!--                    <input-->
<!--                      :data-vv-as="$t('radius.addressField')"-->
<!--                      type="text"-->
<!--                      name="ip address"-->
<!--                      id="ip address"-->
<!--                      :placeholder="$t('radius.ipPlaceholder')"-->
<!--                      class="form-control"-->
<!--                      v-validate="'required|ipv4orDomainName'"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('ip address') }"-->
<!--                      v-model="updatedRadius.hostname"-->
<!--                      :disabled="isDisable"-->
<!--                    />-->
<!--                    <span v-show="errors.has('ip address')" class="help is-danger">-->
<!--                      {{ errors.first('ip address') }}-->
<!--                    </span>-->
<!--                  </div>-->

<!--                  <div class="form-group">-->
<!--                    <label for="name">{{ $t('general.name') }}</label>-->
<!--                    <input-->
<!--                      :data-vv-as="$t('general.name')"-->
<!--                      type="text"-->
<!--                      name="name"-->
<!--                      id="name"-->
<!--                      :placeholder="$t('radius.namePlaceholder')"-->
<!--                      class="form-control"-->
<!--                      v-validate="'required|max:32'"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('name') }"-->
<!--                      v-model="updatedRadius.name"-->
<!--                      :disabled="isDisable"-->
<!--                    />-->
<!--                    <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>-->
<!--                  </div>-->

<!--                  <div class="form-group">-->
<!--&lt;!&ndash;                    <label for="secret">{{ $t('radius.secret') }}</label>&ndash;&gt;-->
<!--                    <label for="secret">{{ $t('radius.password') }}</label>-->
<!--                    <input-->
<!--                      :data-vv-as="$t('radius.password')"-->
<!--                      type="text"-->
<!--                      name="secret"-->
<!--                      id="secret"-->
<!--                      :placeholder="$t('radius.secretPlaceholder')"-->
<!--                      class="form-control"-->
<!--                      v-validate="'required|max:32'"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('secret') }"-->
<!--                      v-model="updatedRadius.secret"-->
<!--                      :disabled="isDisable"-->
<!--                    />-->
<!--                    <span v-show="errors.has('secret')" class="help is-danger">{{ errors.first('secret') }}</span>-->
<!--                  </div>-->

<!--                  <div class="form-group">-->
<!--                    <label for="auth port">{{ $t('radius.authPort') }}</label>-->
<!--                    <input-->
<!--                      :data-vv-as="$t('radius.authPort')"-->
<!--                      type="text"-->
<!--                      name="auth_port"-->
<!--                      id="auth port"-->
<!--                      :placeholder="$t('radius.authPortPlaceholder')"-->
<!--                      class="form-control"-->
<!--                      v-validate="'required|numeric|between:1,65535'"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('auth_port') }"-->
<!--                      v-model="updatedRadius.auth_port"-->
<!--                      :disabled="isDisable"-->
<!--                    />-->
<!--                    <span v-show="errors.has('auth_port')" class="help is-danger">{{ errors.first('auth_port') }}</span>-->
<!--                  </div>-->

<!--                  <div class="form-group">-->
<!--                    <label for="acct port">{{ $t('radius.acctPort') }}</label>-->
<!--                    <input-->
<!--                      :data-vv-as="$t('radius.acctPort')"-->
<!--                      type="text"-->
<!--                      name="acc_port"-->
<!--                      id="acct port"-->
<!--                      :placeholder="$t('radius.acctPortPlaceholder')"-->
<!--                      class="form-control"-->
<!--                      v-validate="'required|numeric|between:1,65535'"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('acc_port') }"-->
<!--                      v-model="updatedRadius.acc_port"-->
<!--                      :disabled="isDisable"-->
<!--                    />-->
<!--                    <span v-show="errors.has('acc_port')" class="help is-danger">{{ errors.first('acc_port') }}</span>-->
<!--                  </div>-->

<!--                  <div class="form-group">-->
<!--                    <label for="location">{{ $t('general.location') }}</label>-->
<!--                    <select-->
<!--                      v-model="updatedRadius.base_location"-->
<!--                      id="location"-->
<!--                      name="location"-->
<!--                      class="form-control"-->
<!--                      :class="{ 'select-disabled': isDisable }"-->
<!--                      :disabled="isDisable"-->
<!--                    >-->
<!--                      <option v-for="location in locationsList" :key="location.id" :value="location.id">-->
<!--                        {{ location.name }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->

<!--                  <h6 class="mt-3">{{ $t('radius.daeSettings') }}</h6>-->
<!--                  &lt;!&ndash;                  dae_client&ndash;&gt;-->
<!--                  <div class="form-group mt-1">-->
<!--                    <label for="dae_client">-->
<!--                      {{ $t('radius.daeClient') }}-->
<!--                      <info :content="$t('radius.daeClientHintInfoMsg')" />-->
<!--                    </label>-->
<!--                    <input-->
<!--                      :data-vv-as="$t('radius.daeClient')"-->
<!--                      type="text"-->
<!--                      name="dae_client"-->
<!--                      id="dae_client"-->
<!--                      :placeholder="$t('radius.daeClientPlaceholder')"-->
<!--                      class="form-control"-->
<!--                      v-validate="'radiusDaeClient'"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('dae_client') }"-->
<!--                      v-model="updatedRadius.dae_client"-->
<!--                      :disabled="isDisable"-->
<!--                      data-vv-validate-on="change"-->
<!--                      @input="daeClientInputHandler"-->
<!--                    />-->
<!--                    <span v-show="errors.has('dae_client')" class="help is-danger">-->
<!--                      {{ errors.first('dae_client') }}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  &lt;!&ndash;                  dae_port&ndash;&gt;-->
<!--                  <div class="form-group mt-1">-->
<!--                    <label for="dae_client">-->
<!--                      {{ $t('radius.daePort') }}-->
<!--                      <info :content="$t('radius.daePortHintInfoMsg')" />-->
<!--                    </label>-->
<!--                    <input-->
<!--                      :data-vv-as="$t('radius.daePort')"-->
<!--                      type="text"-->
<!--                      name="dae_port"-->
<!--                      id="dae_port"-->
<!--                      :placeholder="$t('radius.daePortPlaceholder')"-->
<!--                      class="form-control"-->
<!--                      v-validate="'numeric|between:1,65535'"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('dae_port') }"-->
<!--                      v-model="updatedRadius.dae_port"-->
<!--                      :disabled="isDisable"-->
<!--                      data-vv-validate-on="input"-->
<!--                    />-->
<!--                    <span v-show="errors.has('dae_port')" class="help is-danger">{{ errors.first('dae_port') }}</span>-->
<!--                  </div>-->

<!--                  &lt;!&ndash;                  dae_secret&ndash;&gt;-->
<!--                  <div class="form-group mt-1">-->
<!--                    <label for="dae_client">-->
<!--                      {{ $t('radius.daeSecret') }}-->
<!--                      <info :content="$t('radius.daeSecretHintInfoMsg')" />-->
<!--                    </label>-->
<!--                    <textarea-->
<!--                      name="dae_secret"-->
<!--                      id="dae_secret"-->
<!--                      :placeholder="$t('radius.daeSecretPlaceholder')"-->
<!--                      class="form-control"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('dae_secret') }"-->
<!--                      v-model="updatedRadius.dae_secret"-->
<!--                      :disabled="isDisable"-->
<!--                    />-->
<!--                    <span v-show="errors.has('dae_secret')" class="help is-danger">-->
<!--                      {{ errors.first('dae_secret') }}-->
<!--                    </span>-->
<!--                  </div>-->

<!--                  <div class="form-group mt-3">-->
<!--                    <Switch-component-->
<!--                      v-model="updatedRadius.is_local"-->
<!--                      :disabled="isDisable"-->
<!--                      :label="$t('radius.local')"-->
<!--                      @change="updatedRadius.is_portal = false"-->
<!--                      id="radius-local"-->
<!--                    />-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <Switch-component-->
<!--                      v-model="updatedRadius.is_portal"-->
<!--                      :disabled="isDisable || updatedRadius.is_local"-->
<!--                      :label="$t('radius.portal')"-->
<!--                      id="radius-portal"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">-->
<!--                  <div>-->
<!--                    <button-->
<!--                      v-if="!isDisable"-->
<!--                      type="button"-->
<!--                      :disabled="errors.any() || !enableSaveChanges"-->
<!--                      class="btn btn-outline-success btn-md mr-1"-->
<!--                      @click="updateRadius"-->
<!--                    >-->
<!--                      <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>-->
<!--                      <span v-if="muteEdit" class="loader loader&#45;&#45;mini"></span>-->
<!--                    </button>-->
<!--                    <button-->
<!--                      v-if="!isDisable"-->
<!--                      type="button"-->
<!--                      :disabled="errors.any()"-->
<!--                      class="btn btn-outline-info btn-md"-->
<!--                      @click="copyRaduis"-->
<!--                    >-->
<!--                      <span :class="{ invisible: muteEdit }">{{ $t('general.copy') }}</span>-->
<!--                      <span v-if="muteEdit" class="loader loader&#45;&#45;mini"></span>-->
<!--                    </button>-->
<!--                  </div>-->
<!--                  <div>-->
<!--                    <button-->
<!--                      v-if="!isDisable"-->
<!--                      :class="{ invisible: muteEdit }"-->
<!--                      type="button"-->
<!--                      class="btn btn-outline-danger btn-md mr-1"-->
<!--                      @click="openDeletingDialog(updatedRadius.id)"-->
<!--                    >-->
<!--                      <span :class="{ invisible: muteEdit }">{{ $t('general.delete') }}</span>-->
<!--                      <span v-if="muteEdit" class="loader loader&#45;&#45;mini"></span>-->
<!--                    </button>-->
<!--                    <button-->
<!--                      :class="{ invisible: muteEdit }"-->
<!--                      type="button"-->
<!--                      class="btn btn-outline-secondary btn-md"-->
<!--                      @click="disableEditMode"-->
<!--                    >-->
<!--                      <span v-if="isDisable">{{ $t('general.close') }}</span>-->
<!--                      <span v-else>{{ $t('general.cancel') }}</span>-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="col-lg-12"><router-view></router-view></div>


    <modal xlarge v-if="radiusModalShowing" title="Update or create RADIUS"
           v-model="radiusModalShowing"
           class="modal-info" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{isNew ? $t('radius.createRadiusServer') : $t('radius.editRadiusServer')}}</h4>
      <div class="d-flex flex-row  flex-wrap">
        <div class="card-block pt-0">
          <small class="text-muted" v-if="!isDisable">{{ $t('general.editingMode') }}</small>
          <small class="text-muted" v-if="isDisable">{{ $t('general.readOnlyMode') }}</small>
          <div class="d-flex flex-row justify-content-between">
            <div class="first-column-in-modal-radius ">
              <div class="form-group mt-1">
                <label for="ip address">
                  {{ $t('radius.addressField') }}
                </label>
                <input
                  :data-vv-as="$t('radius.addressField')"
                  type="text"
                  name="ip address"
                  id="ip address"
                  :placeholder="$t('radius.ipPlaceholder')"
                  class="form-control"
                  v-validate="'required|ipv4'"
                  :class="{ input: true, 'is-danger': errors.has('ip address') }"
                  v-model="updatedRadius.hostname"
                  :disabled="isDisable"
                />
                <small>{{ $t('general.ipFieldHint') }}</small>
                <span v-show="errors.has('ip address')" class="help is-danger">
                      {{ errors.first('ip address') }}
                    </span>
              </div>

              <div class="form-group">
                <label for="name">{{ $t('general.name') }}</label>
                <input
                  :data-vv-as="$t('general.name')"
                  type="text"
                  name="name"
                  id="name"
                  :placeholder="$t('radius.namePlaceholder')"
                  class="form-control"
                  v-validate="'required|max:32'"
                  :class="{ input: true, 'is-danger': errors.has('name') }"
                  v-model="updatedRadius.name"
                  :disabled="isDisable"
                />
                <span v-show="errors.has('name')"
                      class="help is-danger">{{ errors.first('name') }}</span>
              </div>

              <div class="form-group">
                <!--                    <label for="secret">{{ $t('radius.secret') }}</label>-->
                <label for="secret">{{ $t('radius.key') }}</label>
                <div class="d-flex w-100">
                  <div class="w-100">
                    <input
                      :type="maskRADIUSKey ? 'password' : 'text'"
                      @input="secretInputHandler"
                      :data-vv-as="$t('radius.key')"
                      name="secret"
                      ref="secret"
                      id="secret"
                      :placeholder="$t('radius.secretPlaceholder')"
                      class="form-control"
                      v-validate="'required|max:32'"
                      :class="{ input: true, 'is-danger': errors.has('secret') }"
                      v-model="updatedRadius.secret"
                      :disabled="isDisable"
                    />
                  </div>
                  <div class="input-group-addon hovered" @click="maskRADIUSKey = !maskRADIUSKey">
                    <i class="fa fa-eye" v-if="maskRADIUSKey"></i>
                    <i class="fa fa-eye-slash" v-if="!maskRADIUSKey"></i>
                  </div>
                </div>
                <span v-show="errors.has('secret')" class="help is-danger">{{ errors.first('secret') }}</span>
              </div>

              <div class="form-group">
                <!--                    <label for="secret">{{ $t('radius.secret') }}</label>-->
                <label for="secret">{{ $t('radius.keyConfirmation') }}</label>
                <div class="d-flex w-100">
                  <div class="w-100">
                    <input
                      :type="maskRADIUSKeyConfirmation ? 'password' : 'text'"
                      v-model="secretConfirmation"
                      @input="secretConfirmationInputHandler"
                      :data-vv-as="$t('radius.keyConfirmation')"
                      name="secret_confirmation"
                      id="secret_confirmation"
                      :placeholder="$t('radius.keyConfirmation')"
                      class="form-control"
                      v-validate="'required|confirmed:secret'"
                      :class="{ input: true, 'is-danger': errors.has('secret_confirmation') }"
                      :disabled="isDisable"
                    />
                  </div>
                  <div class="input-group-addon hovered" @click="maskRADIUSKeyConfirmation = !maskRADIUSKeyConfirmation">
                    <i class="fa fa-eye" v-if="maskRADIUSKeyConfirmation"></i>
                    <i class="fa fa-eye-slash" v-if="!maskRADIUSKeyConfirmation"></i>
                  </div>
                </div>
                <div>
                  <span v-show="errors.has('secret_confirmation')" class="help is-danger">{{ errors.first('secret_confirmation') }}</span>
                </div>

              </div>

              <div class="form-group">
                <label for="auth port">{{ $t('radius.authPort') }}</label>
                <input
                  :data-vv-as="$t('radius.authPort')"
                  type="text"
                  name="auth_port"
                  id="auth port"
                  :placeholder="$t('radius.authPortPlaceholder')"
                  class="form-control"
                  v-validate="'required|numeric|between:1,65535'"
                  :class="{ input: true, 'is-danger': errors.has('auth_port') }"
                  v-model="updatedRadius.auth_port"
                  :disabled="isDisable"
                />
                <span v-show="errors.has('auth_port')" class="help is-danger">{{ errors.first('auth_port') }}</span>
              </div>

              <div class="form-group">
                <label for="acct port">{{ $t('radius.acctPort') }}</label>
                <input
                  :data-vv-as="$t('radius.acctPort')"
                  type="text"
                  name="acc_port"
                  id="acct port"
                  :placeholder="$t('radius.acctPortPlaceholder')"
                  class="form-control"
                  v-validate="'required|numeric|between:1,65535'"
                  :class="{ input: true, 'is-danger': errors.has('acc_port') }"
                  v-model="updatedRadius.acc_port"
                  :disabled="isDisable"
                />
                <span v-show="errors.has('acc_port')" class="help is-danger">{{ errors.first('acc_port') }}</span>
              </div>

              <div class="form-group" v-if="areLocationsVisible">
                <label for="location">{{ $t('general.location') }}</label>
                <select
                  v-model="updatedRadius.base_location"
                  id="location"
                  name="location"
                  class="form-control"
                  :class="{ 'select-disabled': isDisable }"
                  :disabled="isDisable"
                >
                  <option v-for="location in locationsList" :key="location.id" :value="location.id">
                    {{ location.name }}
                  </option>
                </select>
              </div>


            </div>
            <div class="second-column-in-modal-radius">
<!--              <h6 class="mt-3">{{ $t('radius.daeSettings') }}</h6>-->
              <h6 class="mt-2">{{ $t('radius.coaSettings') }}</h6>
<!--              &lt;!&ndash;                  dae_client&ndash;&gt;-->
<!--              <div class="form-group mt-1">-->
<!--                <label for="dae_client">-->
<!--                  {{ $t('radius.daeClient') }}-->
<!--                  <info :content="$t('radius.daeClientHintInfoMsg')"/>-->
<!--                </label>-->
<!--                <input-->
<!--                  :data-vv-as="$t('radius.daeClient')"-->
<!--                  type="text"-->
<!--                  name="dae_client"-->
<!--                  id="dae_client"-->
<!--                  :placeholder="$t('radius.daeClientPlaceholder')"-->
<!--                  class="form-control"-->
<!--                  v-validate="'radiusDaeClient'"-->
<!--                  :class="{ input: true, 'is-danger': errors.has('dae_client') }"-->
<!--                  v-model="updatedRadius.dae_client"-->
<!--                  :disabled="isDisable"-->
<!--                  data-vv-validate-on="change"-->
<!--                  @input="daeClientInputHandler"-->
<!--                />-->
<!--                <span v-show="errors.has('dae_client')" class="help is-danger">-->
<!--                      {{ errors.first('dae_client') }}-->
<!--                    </span>-->
<!--              </div>-->
<!--              &lt;!&ndash;                  dae_port&ndash;&gt;-->
<!--              <div class="form-group mt-1">-->
<!--                <label for="dae_client">-->
<!--                  {{ $t('radius.daePort') }}-->
<!--                  <info :content="$t('radius.daePortHintInfoMsg')"/>-->
<!--                </label>-->
<!--                <input-->
<!--                  :data-vv-as="$t('radius.daePort')"-->
<!--                  type="text"-->
<!--                  name="dae_port"-->
<!--                  id="dae_port"-->
<!--                  :placeholder="$t('radius.daePortPlaceholder')"-->
<!--                  class="form-control"-->
<!--                  v-validate="'numeric|between:1,65535'"-->
<!--                  :class="{ input: true, 'is-danger': errors.has('dae_port') }"-->
<!--                  v-model="updatedRadius.dae_port"-->
<!--                  :disabled="isDisable"-->
<!--                  data-vv-validate-on="input"-->
<!--                />-->
<!--                <span v-show="errors.has('dae_port')" class="help is-danger">{{ errors.first('dae_port') }}</span>-->
<!--              </div>-->

<!--              &lt;!&ndash;                  dae_secret&ndash;&gt;-->
<!--              <div class="form-group mt-1">-->
<!--                <label for="dae_client">-->
<!--                  {{ $t('radius.daeSecret') }}-->
<!--                  <info :content="$t('radius.daeSecretHintInfoMsg')"/>-->
<!--                </label>-->
<!--                <textarea-->
<!--                  name="dae_secret"-->
<!--                  id="dae_secret"-->
<!--                  :placeholder="$t('radius.daeSecretPlaceholder')"-->
<!--                  class="form-control"-->
<!--                  :class="{ input: true, 'is-danger': errors.has('dae_secret') }"-->
<!--                  v-model="updatedRadius.dae_secret"-->
<!--                  :disabled="isDisable"-->
<!--                />-->
<!--                <span v-show="errors.has('dae_secret')" class="help is-danger">-->
<!--                      {{ errors.first('dae_secret') }}-->
<!--                    </span>-->
<!--              </div>-->

<!--              &lt;!&ndash;                  dae_secret&ndash;&gt;-->
<!--              <div class="form-group mt-1 d-flex flex-column">-->
<!--                <label for="dae_secret">-->
<!--                  {{ $t('radius.coaServerKey') }}-->
<!--                </label>-->
<!--                <div class="d-flex w-100">-->
<!--                  <div class="w-100" style="position: relative; background-color: transparent !important;">-->
<!--                    <div v-if="maskCoaServerKey"-->
<!--                         role="button"-->
<!--                         @click="overlayClickHandlerForCoaServerKey"-->
<!--                         style="position: absolute; background-color: transparent !important;"-->
<!--                         class="form-control  coa-server-key-fields coa-server-key_textareaOverlay d-flex justify-content-center align-items-center">-->
<!--                      <div class="h4">-->
<!--                        <i class="fa fa-eye-slash"/>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <textarea-->
<!--                      ref="dae_secret"-->
<!--                      @input="coaServerKeyInputHandler"-->
<!--                      :data-vv-as="$t('radius.coaServerKey')"-->
<!--                      name="dae_secret"-->
<!--                      v-validate="''"-->
<!--                      id="dae_secret"-->
<!--                      :placeholder="$t('radius.coaServerKey')"-->
<!--                      class="form-control coa-server-key-fields"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('dae_secret')}"-->
<!--                      v-model="updatedRadius.dae_secret"-->
<!--                      :disabled="isDisable"-->
<!--                    />-->
<!--                  </div>-->
<!--                  <div class="input-group-addon hovered"-->
<!--                       @click="maskCoaServerKey = !maskCoaServerKey">-->
<!--                    <i class="fa fa-eye" v-if="maskCoaServerKey"></i>-->
<!--                    <i class="fa fa-eye-slash" v-if="!maskCoaServerKey"></i>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                                  <span v-show="errors.has('dae_secret')" class="help is-danger">-->
<!--                      {{ errors.first('dae_secret') }}-->
<!--                    </span>-->
<!--                </div>-->

<!--              </div>-->

<!--              <div class="form-group mt-1 d-flex flex-column">-->
<!--                <label for="coaServerKeyConfirmation">-->
<!--                  {{ $t('radius.coaServerKeyConfirmation') }}-->

<!--                </label>-->
<!--                <div class="d-flex w-100">-->
<!--                  <div class="w-100" style="position: relative; background-color: transparent !important;">-->
<!--                    <div v-if="maskCoaServerKeyConfirmation"-->
<!--                         role="button"-->
<!--                         @click="overlayClickHandlerForCoaServerKeyConfirmation"-->
<!--                         style="position: absolute; background-color: transparent !important;"-->
<!--                         class="form-control  coa-server-key-fields coa-server-key_textareaOverlay d-flex justify-content-center align-items-center">-->
<!--                      <div class="h4">-->
<!--                        <i class="fa fa-eye-slash"/>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <textarea-->

<!--                      :data-vv-as="$t('radius.coaServerKeyConfirmation')"-->
<!--                      @input="coaServerKeyConfirmationInputHandler"-->
<!--                      name="coaServerKeyConfirmation"-->
<!--                      id="coaServerKeyConfirmation"-->
<!--                      :placeholder="$t('radius.coaServerKeyConfirmation')"-->
<!--                      v-validate="{ rules: { required: this.isCoaServerKeyConfirmationRequired, confirmed: 'dae_secret'} }"-->
<!--                      class="form-control coa-server-key-fields"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('coaServerKeyConfirmation') }"-->
<!--                      v-model="coaServerKeyConfirmation"-->
<!--                      :disabled="isDisable"-->
<!--                    />-->

<!--                  </div>-->
<!--                  <div class="input-group-addon hovered"-->
<!--                       @click="maskCoaServerKeyConfirmation = !maskCoaServerKeyConfirmation">-->
<!--                    <i class="fa fa-eye" v-if="maskCoaServerKeyConfirmation"></i>-->
<!--                    <i class="fa fa-eye-slash" v-if="!maskCoaServerKeyConfirmation"></i>-->
<!--                  </div>-->


<!--                </div>-->
<!--                <div>-->
<!--                                    <span v-show="errors.has('coaServerKeyConfirmation')"-->
<!--                                          class="help is-danger">-->
<!--                      {{ errors.first('coaServerKeyConfirmation') }}-->
<!--                    </span>-->
<!--                </div>-->

<!--              </div>-->


                            <!--                  dae_secret-->
              <div class="form-group mt-1 d-flex flex-column">
                <label for="dae_secret">
                  {{ $t('radius.coaServerKey') }}
                </label>
                <div class="d-flex w-100">
                  <div class="w-100" style="position: relative; background-color: transparent !important;">
<!--                    <div v-if="maskCoaServerKey"-->
<!--                         role="button"-->
<!--                         @click="overlayClickHandlerForCoaServerKey"-->
<!--                         style="position: absolute; background-color: transparent !important;"-->
<!--                         class="form-control  coa-server-key-fields coa-server-key_textareaOverlay d-flex justify-content-center align-items-center">-->
<!--                      <div class="h4">-->
<!--                        <i class="fa fa-eye-slash"/>-->
<!--                      </div>-->
<!--                    </div>-->
                    <input
                      :type="maskCoaServerKey ? 'password' : 'text'"
                      ref="dae_secret"
                      @input="coaServerKeyInputHandler"
                      :data-vv-as="$t('radius.coaServerKey')"
                      name="dae_secret"
                      v-validate="''"
                      id="dae_secret"
                      :placeholder="$t('radius.coaServerKey')"
                      class="form-control "
                      :class="{ input: true, 'is-danger': errors.has('dae_secret')}"
                      v-model="updatedRadius.dae_secret"
                      :disabled="isDisable"
                    />
                  </div>
                  <div class="input-group-addon hovered"
                       @click="maskCoaServerKey = !maskCoaServerKey">
                    <i class="fa fa-eye" v-if="maskCoaServerKey"></i>
                    <i class="fa fa-eye-slash" v-if="!maskCoaServerKey"></i>
                  </div>
                </div>
                <div>
                                  <span v-show="errors.has('dae_secret')" class="help is-danger">
                      {{ errors.first('dae_secret') }}
                    </span>
                </div>

              </div>

              <div class="form-group mt-1 d-flex flex-column">
                <label for="coaServerKeyConfirmation">
                  {{ $t('radius.coaServerKeyConfirmation') }}

                </label>
                <div class="d-flex w-100">
                  <div class="w-100" style="position: relative; background-color: transparent !important;">
<!--                    <div v-if="maskCoaServerKeyConfirmation"-->
<!--                         role="button"-->
<!--                         @click="overlayClickHandlerForCoaServerKeyConfirmation"-->
<!--                         style="position: absolute; background-color: transparent !important;"-->
<!--                         class="form-control  coa-server-key-fields coa-server-key_textareaOverlay d-flex justify-content-center align-items-center">-->
<!--                      <div class="h4">-->
<!--                        <i class="fa fa-eye-slash"/>-->
<!--                      </div>-->
<!--                    </div>-->
                    <input
                       :type="maskCoaServerKeyConfirmation ? 'password' : 'text'"
                      :data-vv-as="$t('radius.coaServerKeyConfirmation')"
                      @input="coaServerKeyConfirmationInputHandler"
                      name="coaServerKeyConfirmation"
                      id="coaServerKeyConfirmation"
                      :placeholder="$t('radius.coaServerKeyConfirmation')"
                      v-validate="{ rules: { required: this.isCoaServerKeyConfirmationRequired, confirmed: 'dae_secret'} }"
                      class="form-control "
                      :class="{ input: true, 'is-danger': errors.has('coaServerKeyConfirmation') }"
                      v-model="coaServerKeyConfirmation"
                      :disabled="isDisable"
                    />

                  </div>
                  <div class="input-group-addon hovered"
                       @click="maskCoaServerKeyConfirmation = !maskCoaServerKeyConfirmation">
                    <i class="fa fa-eye" v-if="maskCoaServerKeyConfirmation"></i>
                    <i class="fa fa-eye-slash" v-if="!maskCoaServerKeyConfirmation"></i>
                  </div>


                </div>
                <div>
                                    <span v-show="errors.has('coaServerKeyConfirmation')"
                                          class="help is-danger">
                      {{ errors.first('coaServerKeyConfirmation') }}
                    </span>
                </div>

              </div>


<!--              <div class="form-group mt-3">-->
<!--                <Switch-component-->
<!--                  v-model="updatedRadius.is_local"-->
<!--                  :disabled="isDisable"-->
<!--                  :label="$t('radius.clientOnAP')"-->
<!--                  @change="updatedRadius.is_portal = false"-->
<!--                  id="radius-local"-->
<!--                />-->
<!--              </div>-->
<!--              <div class="form-group">-->
<!--                <Switch-component-->
<!--                  v-model="updatedRadius.is_portal"-->
<!--                  :disabled="isDisable || updatedRadius.is_local"-->
<!--                  :label="$t('radius.clientOnWLC')"-->
<!--                  id="radius-portal"-->
<!--                />-->
<!--                <Info class="ml-h" :content="$t('radius.clientOnWLCHint')" ></Info>-->
<!--              </div>-->
              <div class="form-group">
                <Switch-component
                  @input="clientOnAPClientOnWLCSwitcherHandler"
                  :label-before-switcher="$t('radius.clientOnAP')"
                  v-model="clientOnAPClientOnWLCSwitcherState"
                  :disabled="isDisable"
                  :label="$t('radius.clientOnWLC')"
                  id="radius-portal"
                />
                <Info class="ml-h" :content="$t('radius.clientOnWLCHint')" ></Info>
              </div>

            </div>
          </div>


        </div>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <div class="d-flex flex-row justify-content-between w-100">
          <div>
            <div v-if="!isDisable && isNew">
              <WButton success outline
                customClass="mr-1"
                v-if="!isDisable"
                @click="createRADIUS"
                :disabled="errors.any()"
              >
                <span :class="{ invisible: muteEdit }">{{ $t('general.add') }}</span>
                <span v-if="muteEdit" class="loader loader--mini"></span>
              </WButton>
            </div>
            <div v-if="!isDisable && !isNew">
              <WButton success outline
                customClass="mr-1"
                v-if="!isDisable"
                @click="updateRadius"
                :disabled="errors.any() || !enableSaveChanges"
              >
                <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
                <span v-if="muteEdit" class="loader loader--mini"></span>
              </WButton>
              <WButton info outline
                v-if="!isDisable && !returnToEditWlan && !returnToWlanWizard"
                @click="copyRaduis"
                :disabled="errors.any()"
              >
                <span :class="{ invisible: muteEdit }">{{ $t('general.copy') }}</span>
                <span v-if="muteEdit" class="loader loader--mini"></span>
              </WButton>
            </div>

          </div>
          <div>
            <WButton secondary outline
              @click="closeRadiusModal"
            >
              {{ $t('general.close') }}
            </WButton>
          </div>
        </div>

      </div>
    </modal>


    <modal title="Delete RADIUS" v-model="deletingRadiusModal" class="modal-danger" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('radius.deleteRADIUSModalTitle') }}</h4>
      {{ $t('radius.confirmDeletingModal') }}
      <b>{{ radiusName(radiusIdToDelete) }}</b>
      ?
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="cancelDeleting"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteRadius"
        >
          {{ $t('general.yesDelete') }}
        </WButton>
      </div>
    </modal>

        <modal title="Delete RADIUS" v-model="deletingRadiusModalForMultiselect" class="modal-danger" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('radius.deleteRADIUSModalTitle') }}</h4>
      {{ $t('radius.confirmDeletingModal') }}
      ?
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="cancelMultiselectDeleting"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteMultiselectedRadius"
        >
          {{ $t('general.yesDelete') }}
        </WButton>
      </div>
    </modal>
  </div>
</template>

<script>
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';
import Modal from '../../components/Modal.vue';
import radiusService from '../../services/radiusService';
import commonService from '../../services/commonService';
import radiusPollingService from '../../services/radiusPollingService';
import Filters from '../../filters';
import locationService from '../../services/locationService';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import helpers from '../../helpers';
import Info from '../../components/Universal/info-icon.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';
import CheckboxComponent from '../../components/Universal/Checkbox-component.vue';

export default {
  name: 'Radius',
  components: { Modal, SwitchComponent, Info, SelectComponentV2, SelectComponentV3ForUseInLocationSelectors, CheckboxComponent },
  data() {
    return {
      maskCoaServerKeyConfirmation: true,
      maskCoaServerKey: true,
      maskRADIUSKeyConfirmation: true,
      maskRADIUSKey: true,
      secretConfirmation: '',
      coaServerKeyConfirmation: '',
      selectedRADIUSId: [],
      delayTimer: false,
      isNew: false,
      radiusModalShowing: false,
      offset: 0,
      limit: 10,
      totalResult: 0,
      showLocationFilter: true,
      locationInFiltersData: [],
      isEditVisible: false,
      updatedRadius: {},
      deletingRadiusModal: false,
      deletingRadiusModalForMultiselect: false,
      radiusIdToDelete: null,
      enableSaveChanges: false,

      filters: {
        query: '',
        typeFilter: '',
        location: 'All locations',
        with_childs: false,
        sort_by: {
          order: 1,
          field: 'name'
        }
      },
      filteredRadius: []
    };
  },
  computed: {
    clientOnAPClientOnWLCSwitcherState: {
      get() {
        try {
          if (this.updatedRadius.is_local === true && this.updatedRadius.is_portal === false) {
            return false;
          } else if (this.updatedRadius.is_local === false && this.updatedRadius.is_portal === true) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      },
      set(newVal) {
        // console.log(newVal)
      }
    },
    isLoadingAll() {
      return this.loadingRadius;
    },
    returnToEditWlan(){
      return this.$store.state.returnToEditWlan
    },
    returnToWlanWizard() {
      return this.$store.state.returnToWlanWizard
    },
    isCoaServerKeyConfirmationRequired() {
      try {
        if (this.updatedRadius.dae_secret !== '' && this.updatedRadius.dae_secret !== undefined) {
          return true;
        } else {
          return false
        }
      } catch (e) {
        console.log(e)
        return false
      }
    },
    currentRouteName() {
      return this.$route.name;
    },
    isAllChecked() {
      let result = true;
      const radiusIdsList = helpers.combineIdsArrayFromObjectsArray(this.radiusList);
      if (radiusIdsList && radiusIdsList.length && this.selectedRADIUSId && this.selectedRADIUSId.length) {
        radiusIdsList.forEach((id) => {
          if (!this.selectedRADIUSId.includes(id)) {
            return (result = false);
          }
        });
      } else {
        return (result = false);
      }
      return result;
    },
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    maxPageNumber() {
      // console.log(Math.floor(this.totalResult / this.limit))
      if (this.totalResult % this.limit !== 0) {
        return Math.floor(this.totalResult / this.limit) + 1;
      } else {
        return Math.floor(this.totalResult / this.limit);
      }
    },
    currentPageNumber() {
      try {
        function pageNumber(total, limit, offset) {
          return offset >= total ? -1 : parseInt(offset / limit) + 1;
        }

        return pageNumber(this.totalResult, this.limit, this.offset);

      } catch (e) {
        console.log(e)

      }
    },
    previousPageButtonDisabled() {
      if (this.isWlansLoading) {
        return true;
      }
      if (this.currentPageNumber === 1) {
        return true
      }
      return false
    },
    nextPageButtonDisabled() {
      if (this.isWlansLoading) {
        return true;
      }
      if (this.currentPageNumber >= this.maxPageNumber) {
        return true
      }
      return false
    },
    firstPageButtonDisabled() {
      // if (this.currentPageNumber === this.maxPageNumber) {
      //   return true
      // }
      if (this.isWlansLoading) {
        return true;
      }
      if (this.currentPageNumber === 1) {
          return true
      }
      return false
    },
    lastPageButtonDisabled() {
      if (this.isWlansLoading) {
        return true;
      }
      if (this.currentPageNumber === this.maxPageNumber) {
        return true
      }
      return false
    },

    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    showAddButton() {
      return this.$route.name === 'Radius';
    },
    editVisible() {
      return typeof this.$store.state.editRadiusIndexForRadiusV2Page === 'string' && this.$store.state.editRadiusIndexForRadiusV2Page.length;
    },
    muteEdit() {
      return this.$store.state.muteRadiusEdit;
    },
    radiusList() {
      return this.$store.state.radiusListForRadiusV2Page;
    },
    radiusOperations() {
      return this.$store.state.radiusOperations;
    },
    loadingRadius() {
      return this.$store.state.loadingRadius;
    },
    radiusEditCurrent() {
      this.editInited = false;
      const { radiusListForRadiusV2Page } = this.$store.state;
      let currentRadius;
      radiusListForRadiusV2Page.forEach((radius) => {
        if (radius.id == this.$store.state.editRadiusIdForRadiusV2Page) {
          currentRadius = radius;
        }
      });

      this.updatedRadius = { ...currentRadius };
      this.editInited = true;
      return currentRadius;
    },
    radiusEditCurrentId() {
      return this.$store.state.radiusListForRadiusV2Page[this.$store.state.editRadiusIndexForRadiusV2Page].id;
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    isFiltersActive() {
      return (
        this.filters.location !== 'All locations' ||
        this.filters.query !== '' || this.filters.typeFilter !== '' ||
        this.filters.sort_by.field !== 'name' || this.filters.sort_by.order !== 1
      );
    },
    radiusSelectedIndex() {
      return this.$store.state.editRadiusIndexForRadiusV2Page;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    userDataLocation() {
      // this.filters.location = this.$store.state.userData.location;
      return this.$store.state.userData.location;
    },
    isNoData() {
      return this.filteredRadius.length === 0;
    }
  },
  watch: {
    radiusList() {
      radiusPollingService.startPolling(this);
    },
    currentRouteName: {
      handler(val, oldVal) {
        if (val === 'Radius' && (oldVal === 'radius-step-1' || 'radius-step-1')) {
          // console.log('currentRouteName watcher worked')
          this.getPaginatedRadiusListWithClearPagination()
        }
      }
    },
    isLoadingAll: {
      handler(val, oldVal) {
        try {
          if (oldVal === true && val === false && this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('radiusName')) {
            if (Array.isArray(this.radiusList) && this.radiusList.length > 0 && this.radiusList.find(radius => {
              return radius.name === this.$route.params.radiusName;
            })) {
              this.$nextTick(() => {
                const radius = this.radiusList.find(radius =>{
                  return radius.name === this.$route.params.radiusName;
                })
                // console.log(radius)
                 this.openEditModal(radius);
                // и удаляем чтобы этот вотчер больше не отрабатывал если список  обновляется (и isLoadingAll меняет состояние)
                delete this.$route.params.radiusName;
              });
            }
          }

        } catch (e) {
          console.log(e);
        }
      }
    },
    radiusOperations() {
      this.radiusSpinner();
    },
    updatedRadius: {
      handler(val, oldVal) {
        if (!oldVal) {
          this.enableSaveChanges = false;
        } else if (val && oldVal && val.id !== oldVal.id) {
          this.enableSaveChanges = false;
        } else if (!val) {
          this.enableSaveChanges = false;
        } else {
          this.enableSaveChanges = true;
        }
      },
      deep: true
    }
  },
  methods: {
    clientOnAPClientOnWLCSwitcherHandler(value) {
      // console.log('clientOnAPclientOnWLCSwitcherHandler value', value)
      if (value === false) {
        // console.log('setClientOnAP')
        this.setClientOnAP();
      } else if (value === true) {
        // console.log('setClientOnWLC')
        this.setClientOnWLC();
      }
    },
    setClientOnAP() {
      this.updatedRadius.is_local = true;
      this.updatedRadius.is_portal = false;
    },
    setClientOnWLC() {
      this.updatedRadius.is_local = false;
      this.updatedRadius.is_portal = true;
    },
    overlayClickHandlerForCoaServerKey() {
      this.maskCoaServerKey = false;
      document.getElementById('dae_secret').focus();
    },
    overlayClickHandlerForCoaServerKeyConfirmation() {
      this.maskCoaServerKeyConfirmation = false;
      document.getElementById('coaServerKeyConfirmation').focus();
    },
    maskAllKeysFields() {
      this.maskCoaServerKeyConfirmation = true;
      this.maskCoaServerKey = true;
      this.maskRADIUSKeyConfirmation = true;
      this.maskRADIUSKey = true;
    },
    unmaskAllKeysFields() {
      this.maskCoaServerKeyConfirmation = false;
      this.maskCoaServerKey = false;
      this.maskRADIUSKeyConfirmation = false;
      this.maskRADIUSKey = false;
    },

    secretConfirmationInputHandler(){
      // console.log('here')
      // this.clearErrorsForInput('secret_confirmation');
      this.$validator.validate('secret_confirmation');
    },
    secretInputHandler() {
      this.$validator.validate('secret_confirmation');
    },
    coaServerKeyConfirmationInputHandler() {
      this.$validator.validate('coaServerKeyConfirmation');
    },
    coaServerKeyInputHandler() {
      this.$validator.validate('coaServerKeyConfirmation');
    },
    clearErrorsForInput(inputName) {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has(inputName)) {
          this.errors.remove(inputName);
        }
      }
    },
    cancelMultiselectDeleting(){
      this.deletingRadiusModalForMultiselect = false;
    },
    clearSelectedRADIUSId(){
      this.selectedRADIUSId = [];
    },
    checkRADIUS(e) {
      e.stopPropagation()
      // console.log('checlradius')
      const selectedNode = e.target.closest('[data-index]');
      const { id } = selectedNode.dataset;
      if (this.selectedRADIUSId.includes(id)) {
        this.selectedRADIUSId.splice(this.selectedRADIUSId.indexOf(id), 1);
      } else {
        this.selectedRADIUSId.push(id);
      }
    },
    checkAllRADIUS() {
      // const radiusLength = this.filteredRadius.length;
      const radiusLength = this.filterItems(this.radiusList).length
      const filtredSelectedId = document.querySelectorAll('.radius-row');
      // console.log(radiusLength)
      if (radiusLength) {
        if (!this.isAllChecked) {
          this.selectedRADIUSId = [...filtredSelectedId].map((cpe) => cpe.dataset.id);
        } else {
          this.selectedRADIUSId = [];
          this.disableEditMode();
        }
      }
    },
    toggleSortMode() {
      // this.resetPagintaion();
      if (this.filters.sort_by.order === 1) {
        this.filters.sort_by.order = -1;
      } else {
        this.filters.sort_by.order = 1;
      }
      this.getPaginatedRadiusListWithClearPagination();
    },
    handleSelectSortField() {
      this.getPaginatedRadiusListWithClearPagination();
    },
    createRADIUS(){
      console.log('create clicked')
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            console.log('validation ok')
            this.createRADIUSAPICall();
          } else {

          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    createRADIUSAPICall() {
      const transformedQuery = { ...this.updatedRadius };
      transformedQuery.port = parseInt(transformedQuery.port);

      this.axios.post(`${API_URL || ''}/api/radius`, { action: 'C', items: { 0: transformedQuery } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          this.$store.commit('clearNewRadiusData');
          radiusPollingService.hadleOperationResponse(response.data.data.items);
          // radiusService.getRadius(this);
          // radiusService.getRadiusV2(this)
          this.getPaginatedRadiusListWithClearPagination()
          this.closeRadiusModal();
          // this.$parent.nextStep();
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
    },
    daeClientInputHandler(e) {
      // очищаем ошибку dae_client, если она есть, каждый раз когда начанаем вводить dae_client
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('dae_client')) {
          // console.log('has dae_client error');
          this.errors.remove('dae_client');
        }
      }
    },
    searchInputHandler(value) {
      this.clearSelectedRADIUSId();
      this.filters.query = value;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        // this.refreshEvents();
        this.getPaginatedRadiusListWithClearPagination()
      }, 1000);
    },
    handleItemsPerPageSelect() {
      this.getPaginatedRADIUS();
    },
    showRadiusModal() {
      this.radiusModalShowing = true;
    },
    closeRadiusModal() {
      if (this.returnToEditWlan) {
        // radiusService.getRadius(this);
        setTimeout(() => {
          this.$router.push({name: 'Wlans'})
        }, 200);
      }
      if (this.returnToWlanWizard) {
        // radiusService.getRadius(this);
        setTimeout(() => {
          this.$router.push({name: 'wlanWizardV2'})
        }, 200);
      }
      this.radiusModalShowing = false;
      this.isNew = false
    },
    firstPageClickHandler() {
      if (this.firstPageButtonDisabled) {
        return
      }
      this.disableEditMode();
      this.offset = 0;
      this.getPaginatedRADIUS();

    },
    previousPageClickHandler() {
      this.disableEditMode();
      if (this.previousPageButtonDisabled) {
        return
      }
      this.offset = this.offset - this.limit;
      this.getPaginatedRADIUS();

    },
    nextPageClickHandler() {
      this.disableEditMode();
      if (this.nextPageButtonDisabled) {
        return
      }
      this.offset = this.offset + this.limit;
      this.$nextTick(() => {
        this.getPaginatedRADIUS();
      })

    },
    lastPageClickHandler() {
      if (this.lastPageButtonDisabled) {
        return;
      }
      this.disableEditMode();
      this.offset = (this.maxPageNumber - 1) * this.limit
      this.getPaginatedRADIUS();

    },
    getPaginatedRADIUS() {
      this.clearSelectedRADIUSId();
      radiusService.getRadiusV2(this, true);
    },

    selectLocationInFilters(location) {
      this.clearSelectedRADIUSId();
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.getPaginatedRadiusListWithClearPagination()

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleEdit(e) {
      if (e.target.dataset.action === 'delete') {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { id } = selectedNode.dataset;

      this.$store.commit('setRadiusEditIndexForRadiusV2Page', index);
      this.$store.commit('setRadiusEditIdForRadiusV2Page', id);
    },

    openEditModal(radiusObject) {
      this.isNew = false;
      this.updatedRadius = JSON.parse(JSON.stringify(radiusObject))
      this.secretConfirmation = this.updatedRadius.secret;
      this.coaServerKeyConfirmation = this.updatedRadius.dae_secret;
      this.maskAllKeysFields();
      this.showRadiusModal();

    },
    openCreateModal() {
      this.isNew = true;
      this.$store.commit('clearNewRadiusData');
      this.updatedRadius = JSON.parse(JSON.stringify(this.$store.state.newRadiusData));
      this.updatedRadius.base_location = this.$store.state.userData.base_location;
      this.secretConfirmation = this.updatedRadius.secret;
      this.coaServerKeyConfirmation = this.updatedRadius.dae_secret;
      this.unmaskAllKeysFields();
      this.showRadiusModal();
    },

    disableEditMode() {
      this.$store.commit('setRadiusEditIndexForRadiusV2Page', null);
    },
    // resetAllFilters() {
    //   this.disableEditMode();
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters = {
    //     query: '',
    //     typeFilter: '',
    //     location: 'All locations',
    //     with_childs: false
    //   };
    //   this.refreshRadiusList();
    // },
    resetAllFilters() {
      this.disableEditMode();
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters = {
        query: '',
        typeFilter: '',
        // location: 'All locations',
        // with_childs: false
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.filters.sort_by = 'name';
      this.filters.order = 1;
      this.getPaginatedRadiusListWithClearPagination();
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    updateRadius() {
      if (this.updatedRadius.dae_port === '') {
        // если ничего не ввели ставим в значение по умолчанию
        // this.updatedRadius.dae_port = '3799';

        // теперь не ставим по умолчанию, так как вообще обрали это поле из UI (DF-65)
      }
      this.$validator.validateAll({}).then(
        (result) => {
          if (result) {
            radiusService.updateRadiusV2(this);
            this.closeRadiusModal();
            if (this.returnToEditWlan) {
              // radiusService.getRadius(this);
              setTimeout(() => {
                this.$router.push({name: 'Wlans'})
              }, 200);
            }
            if (this.returnToWlanWizard) {
              // radiusService.getRadius(this);
              setTimeout(() => {
                this.$router.push({name: 'wlanWizardV2'})
              }, 200);
            }
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    copyRaduis() {
      const copy = { ...this.updatedRadius };

      this.axios.post(`${API_URL || ''}/api/radius`, { action: 'C', items: { 0: copy } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          this.$store.commit('clearNewRadiusData');
          radiusPollingService.hadleOperationResponse(response.data.data.items);
          // radiusService.getRadiusV2(this, true);
          this.closeRadiusModal();
          this.getPaginatedRadiusListWithClearPagination();
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
    },
    openRadiusWizard() {
      this.$router.push({ name: 'radius-wizard' });
    },
    openDeletingDialog(id) {
      this.radiusIdToDelete = id;
      this.deletingRadiusModal = true;
    },
    openMultiselectDeletingDialogforRadius(){
      this.deletingRadiusModalForMultiselect = true;
    },
    deleteRadius() {
      radiusService.deleteRadiusV2(this);
      this.radiusIdToDelete = '';
    },
    deleteMultiselectedRadius(){
      radiusService.deleteMultiselectedRadiusV2(this, JSON.parse(JSON.stringify(this.selectedRADIUSId)))
    },
    cancelDeleting() {
      this.deletingRadiusModal = false;
      this.radiusIdToDelete = '';
    },
    refreshRadiusList() {
      radiusService.getRadiusV2(this, true);
    },

    getPaginatedRadiusListWithClearPagination() {
      this.clearSelectedRADIUSId();
      this.offset = 0;
      radiusService.getRadiusV2(this, true);
    },

    radiusName(id) {
      const radiusName = commonService.radiusNamebyId(id);
      return radiusName;
    },
    // filterItems(items) {
    //   let filteredRadius = items;
    //
    //   filteredRadius = Filters.radiusSearchQuery(filteredRadius, this.filters.query);
    //   filteredRadius = Filters.radiusTypeFilters(filteredRadius, this.filters.typeFilter);
    //   filteredRadius = Filters.locationFilter(filteredRadius, this.filters.location);
    //   this.filteredRadius = filteredRadius;
    //   return filteredRadius;
    // },
    filterItems(items) {
      let filteredRadius = items;

      filteredRadius = Filters.radiusSearchQuery(filteredRadius, this.filters.query);
      filteredRadius = Filters.radiusTypeFilters(filteredRadius, this.filters.typeFilter);
      // filteredRadius = Filters.locationFilter(filteredRadius, this.filters.location);
      this.filteredRadius = filteredRadius;
      return filteredRadius;
    },
    filterItems(items) {
      // let filteredRadius = items;
      //
      // filteredRadius = Filters.radiusSearchQuery(filteredRadius, this.filters.query);
      // filteredRadius = Filters.radiusTypeFilters(filteredRadius, this.filters.typeFilter);
      // // filteredRadius = Filters.locationFilter(filteredRadius, this.filters.location);
      // this.filteredRadius = filteredRadius;
      // return filteredRadius;
      return items;
    },
    setFilter(filterName, value) {
      this.disableEditMode();
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
        return;
      }
      this.filters[filterName] = '';
    },
    radiusSpinner(forceId) {
      const { radiusOperations } = this.$store.state;
      const radiusRows = document.querySelectorAll('tr');

      radiusRows.forEach((radiusRow) => {
        radiusRow.classList.remove('radius-loading');
      });

      if (forceId) {
        radiusOperations.forEach((operation) => {
          if (operation.model_id === forceId) {
            setTimeout(() => {
              const radiusRow = document.querySelector(`[data-id="${forceId}"]`);
              if (radiusRow) {
                radiusRow.classList.add('radius-loading');
              }
            }, 1);
          }
        });
      } else if (radiusOperations.length === 0) {
      } else {
        radiusOperations.forEach((operation) => {
          radiusRows.forEach((radiusRow) => {
            if (radiusRow.dataset.id === operation.model_id) {
              radiusRow.classList.add('radius-loading');
            }
          });
        });
      }
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    }
  },
  // created() {
  //   radiusService.getRadius(this);
  //   locationService.getLocations(this);
  //   this.$store.state.editRadiusIndexForRadiusV2Page = '';
  // },
  created() {
    let inHeaderLocationSelectedApplying = true;
    if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('radiusName')) {
      inHeaderLocationSelectedApplying = false;
      if (this.isSelectedDefaultLocationForRequests) {
        inHeaderLocationSelectedApplying = true;
      }
      // console.log('here')
      this.filters.query = this.$route.params.radiusName;
      // а далее логика  в вотчере isLoadingAll
    }

    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    radiusService.getRadiusV2(this, true);
    locationService.getLocations(this);
    this.$store.state.editRadiusIndexForRadiusV2Page = '';
  }
};
</script>
<style lang="scss">

      .input-group-for-location-select-in-radius--filters {

    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }
      .select-location-in-radius--filters .select__list-container {
    width: 400px;
  }
.radius {
  display: flex;
}

.radius-list,
.radius-edit {
  transition: width 0.2s;
}

.radius-list {
  display: inline-block;
  width: 100%;
}

.radius-edit {
  display: inline-block;
  width: 0%;
  word-wrap: break-word;
}

.radius-edit .card-header {
  border: 0;
}

.edit-visible .radius-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 60%;
}

.edit-visible .radius-edit {
  transition: width 0.2s;
  width: 40%;
  min-width: 260px;
}

.radius-row {
  transition: background 0.15s;
  cursor: pointer;
}

.radius-row.selected {
  background: #d9e3ec;
}

.radius-row:hover {
  background: rgba(236, 236, 236, 0.59);
}
.radius-row-nodata {
  text-align: center;
}

/*Style for filters*/
.btn.btn-outline-warning:hover {
  background-color: #fad840;
}
.btn.btn-outline-warning.active {
  background-color: #f8cb00;
}

.radius-loading {
  animation-name: blink;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.edit-btn-close {
  line-height: 14px;
  font-size: 14px !important;
  height: 27.5px;
  width: 29px;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.loader-backdrop-radius:after {
  position: absolute;
  top: 30px;
}
</style>
<style lang="scss" scoped>
  .first-column-in-modal-radius,   .second-column-in-modal-radius{
    width: 45% !important;
  }
  /*.coa-server-key_textareaBlured{*/
  /*  color: transparent;*/
  /*  text-shadow: 0 0 5px rgba(0,0,0, 3.0);*/
  /*  !*backdrop-filter: blur(3px);*!*/

  /*}*/
  .coa-server-key-fields {
    height: 10em !important;
  }

  .coa-server-key_textareaOverlay {
    /*color: transparent;*/
    background-color: transparent;
    backdrop-filter: blur(2px)
  }
</style>
